import { Page, WebSite, WebSiteConfig } from '../types';

const pages: Record<string, Page> = {
  home: {
    pageType: "landing-social",
    menu: {
      useBackgroundGradient: false,
      backgroundColor: "black",
      color: "#fdfa52",
    },
    textColor: "black",
    title: "Welcome to Gahanna RV & Boat Storage",
    subtitle: "We are a secure, gated and conveniently located vehicle storage facility serving Gahanna and Greater Columbus",
    // subtitle: "Our facility is secure, gated and offers exterior, gravel parking spaces.",
    // subtitle2: "We are conveniently located within 1 mile from major highways 270, 670 and U.S. 62 and only 3 miles from the Columbus airport.",
    content: {
      services: [
        {
          title: "Our Location Offers",
          list: [
            {
              name: "Secure Storage",
              description: "Our facility features a secure fence, an automated gate, and exterior gravel parking spaces",
            },
            {
              name: "Mobile App / Keypad Access",
              description: "Customers have access to the gate via an iOS or Android app to operate the gate from your vehicle when on site; unique codes are also provided for keypad access"
            },
            {
              name: "Convenient Location",
              description: "We are conveniently located within 3 miles of CMH (John Glenn Columbus Int'l Airport), and within 1 mile of major highways 270, 670 and U.S. 62",
            },
            // {
            //   name: "On-site Trailer Service",
            //   description: "Gahanna RV Plus operates on location and is a long time trailer service.  Avoid multiple service trips and have your RV serviced by experts so everything is ready when you're ready to Go!"
            // },
            // {
            //   name: "On-site Propane Refills",
            //   description: "Quick and easy propane refills on location in front of the shop",
            // }

          ],
        },
        {
          title: "Storage Rates",
          list: [
            {
              name: "Back-in space (max 30ft)",
              description: "$85 monthly"
            },
            {
              name: "Back-in space (max 40ft)",
              description: "$115 monthly"
            },
            {
              name: "Pull-through space (max 50ft)",
              description: "$135 monthly"
            },
            {
              name: "Work vans, trucks, pickups, buses or other vehicles",
              description: "Please call or email to inquire"
            },
          ]
        }
      ],
      reviews: [
        {
          text: "Amazing guys in charge. Easy to work with, great communication, fair rent price, and then when we needed to change our minds after 2 months allowed for easy cancellation. Will use again and refer anyone!.",
          author: "J. Haywood",
          stars: 5,
        },
        {
          text: "I parked my boat at Gahanna RV & Boat for about 1 1/2 years never any issues. Great experience. I would recommend this location for storage.",
          author: "K. Ward",
          stars: 5,
        },
        {
          text: "Affordable and close to the airport! Very easy to work with.",
          author: "T. Lang",
          stars: 5,
        },

      ],
      iconList: [
        { name: 'fab fa-medium-m', link: 'http://www.medium.com/@fabiovalentino_' },
        { name: 'fab fa-gitlab', link: 'http://www.gitlab.com/lagosoft' },
        { name: 'fab fa-github', link: 'http://www.github.com/lagofvc' },
        { name: 'fab fa-linkedin', link: 'http://www.linkedin.com/in/fabiovalentino' },
        { name: 'fab fa-meetup', link: 'http://www.meetup.com/Latinx-Tech-PDX' },
      ],
      form: 'STORAGE'
    },
    gradient: {
      direction: "to right",
      first: "#fdfa52",
      second: "#FFAE42"
    }
  },
  "storage-form": {
    pageType: "storage-form",
    isHidden: false,
    menu: {
      useBackgroundGradient: false,
      backgroundColor: "black",
      color: "#fdfa52",
    },
    textColor: "black",
    title: "Storage Inquiry & Intake Form",
    subtitle: "Let's start with some basic information and we will reach out to you shortly!",
    content: {
      form: 'STORAGE'
    },
    gradient: {
      direction: "to right",
      first: "#fdfa52",
      second: "#FFAE42"
    }
  },
  reserve: {
    isHidden: false,
    pageType: "item-list",
    menu: {
      useBackgroundGradient: false,
      backgroundColor: "black",
      color: "#fdfa52",
    },
    textColor: "black",
    title: "Available Spaces",
    subtitle: `Select a space for your vehicle and click "Reserve Now" to continue... `,
    content: {
      items: []
    },
    gradient: {
      direction: "to right",
      first: "#fdfa52",
      second: "#FFAE42"
    }
  },
}

const config: WebSiteConfig = {
  browserTabTitle: "Gahanna RV & Boat Storage",
  logo: {
    name: "Gahanna RV & Boat Storage",
    brandLogoUri: "Gahanna_RVnBoat_Logo.svg"
  },
  text: {
    color: "#fff",
    fonts: {
      primary: {
        family: "Exo",
        url: "https://fonts.googleapis.com/css?family=Exo&display=swap"
      }
    }
  },
  domain: "gahannarvandboat.com",
  facebook: {
    pixel: {
      id: "210715353043125",
      imageSrc: "https://www.facebook.com/tr?id=210715353043125&ev=PageView&noscript=1"
    },
    metadata: {
      "og:title": {
        attribute: "content",
        value: "gahannarvandboat.com"
      },
      "og:description": {
        attribute: "content",
        value: "Affordably store your boat or RV with Gahanna RV &amp; Boat in Gahanna, Ohio serving the greater Columbus area"
      },
      "og:type": {
        attribute: "content",
        value: "website"
      },
      "og:url": {
        attribute: "content",
        value: "https://fabiovalentino.com/"
      },
      "og:image": {
        attribute: "content",
        value: "https://fabiovalentino.com/thumbnail.png"
      },
      "fb:app_id": {
        attribute: "content",
        value: "685048371848508"
      }
    }
  },
  defaultRoute: "home",
  footer: {
    businessHours: [
      {
        day: "Mon",
        hours: "8:00am - 3:30pm"
      },
      {
        day: "Tue",
        hours: "8:00am - 3:30pm"
      },
      {
        day: "Wed",
        hours: "8:00am - 3:30pm"
      },
      {
        day: "Thu",
        hours: "8:00am - 3:30pm"
      },
      {
        day: "Fri",
        hours: "8:00am - 3:30pm"
      },
      {
        day: "Sat",
        hours: "8:00am - 3:30pm"
      },
      {
        day: "Sun",
        hours: "closed"
      }
    ],
    email: "hello@gahannarvandboat.com",
    phone: "(614) 699-2495",
    address: "720 Agler Rd",
    city: "Gahanna",
    state: "OH",
    zip: "43230",
    social: [
      {
        platformName: "Yelp!",
        link: "https://www.yelp.com/biz/gahanna-rv-and-boat-storage-columbus"
      },
      {
        platformName: "Google",
        link: "https://www.google.com/maps/place/Gahanna+RV+%26+Boat+Storage/@40.0208293,-82.9030796,17z/data=!3m1!4b1!4m6!3m5!1s0x883861fcb5f3df29:0xeb3a49af8bf7e4c5!8m2!3d40.0208293!4d-82.9030796!16s%2Fg%2F1tf6x_xf?entry=ttu"
      },
      {
        platformName: "Facebook",
        link: "https://www.facebook.com/profile.php?id=100057107933671"
      },
    ]
  }
}

const website: WebSite = {
  config,
  pages,
  pageOrder: ['(614) 699-2495', 'hello@gahannarvandboat.com'],
  // pageOrder: ['home', 'reserve'],
}

export default website

